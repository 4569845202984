import { PaymentSummary, Reservation, ReservationDetails, ReservationStatus } from '@models/reservation'
import Decimal from 'decimal.js'
import { createCombinedDateWithTime } from '@helpers/date-helper'
import { isAfter, isBefore } from 'date-fns'

export const isPendingReservation = (reservation: Reservation | ReservationDetails) =>
  !reservation.checked_in &&
  (reservation.status === ReservationStatus.initial || reservation.status === ReservationStatus.confirmed)

export const isCheckedInReservation = (reservation: Reservation | ReservationDetails) =>
  reservation.checked_in &&
  (reservation.status === ReservationStatus.initial || reservation.status === ReservationStatus.confirmed)

export const isAfterDepartureTime = (reservation: Reservation) => {
  const departureTime = createCombinedDateWithTime(reservation.date_to, reservation.departure_time)
  return isAfter(new Date(), departureTime) && !reservation.checked_out_online
}

export const isInCheckOutTimeRange = (reservation: Reservation) => {
  const RECEPTION_AVAILABLE_FROM = '07:00'

  const departureTime = createCombinedDateWithTime(reservation.date_to, reservation.departure_time)
  const receptionAvailableTime = createCombinedDateWithTime(reservation.date_to, RECEPTION_AVAILABLE_FROM)
  return (
    isAfter(new Date(), receptionAvailableTime) &&
    isBefore(new Date(), departureTime) &&
    !reservation.checked_out_online
  )
}

export const getRestToPay = (paymentSummary: PaymentSummary): Decimal =>
  new Decimal(paymentSummary.rest.deposit)
    .add(paymentSummary.rest.installment)
    .add(paymentSummary.required.deposit)
    .add(paymentSummary.required.installment)

export const getRestToPayInstallment = (paymentSummary: PaymentSummary): Decimal =>
  new Decimal(paymentSummary.rest.installment).add(paymentSummary.required.installment)

export const getRestToPayDeposit = (paymentSummary: PaymentSummary): Decimal =>
  new Decimal(paymentSummary.rest.deposit).add(paymentSummary.required.deposit)

export const shouldBePaidAllAtOnce = (paymentSummary: PaymentSummary): boolean =>
  new Decimal(getRestToPayDeposit(paymentSummary)).gt(0) &&
  new Decimal(getRestToPayInstallment(paymentSummary)).gt(0) &&
  !paymentSummary.rest.required_date &&
  !paymentSummary.rest.required_date_deposit

export const isReservationPaid = (reservationDetails: ReservationDetails | undefined | null): boolean =>
  reservationDetails?.is_paid ?? false
